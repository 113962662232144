import MainLayout from '@layout/mainLayout';
import PlainLayout from "@layout/plainLayout";
import {useTranslation} from 'next-i18next';
import getConfig from 'next/config'
import Form from "@components/Form/form";
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';

const {serverRuntimeConfig, publicRuntimeConfig} = getConfig()

import styles from './index.module.scss';

export const getServerSideProps = async ({locale, query}) => {
  const {code} = query;
  // const response = await fetch(
  //   serverRuntimeConfig.strapiUrl + '/entry-codes',
  //   {
  //     headers: {
  //       Accept: 'application/json',
  //       Authorization: 'bearer ' + serverRuntimeConfig.strapiToken
  //     }
  //   }
  // )
  //   .then(res => res.json());
  //
  // const codeRemap = response.data.map((entry) => (
  //   entry.attributes.code
  // ))

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      // validCode: codeRemap.includes(code),
      validCode: code === 'hw24',
      code: code ?? ''
    },
  };
};

const Home = ({validCode, code}) => {
  const {t} = useTranslation();

  if(validCode){
    return (
      <div className={styles.home}>
        <div className={styles.home__text}>
          <h1>FrankenSchmidt Halloween Party 2024</h1>
          <h3>Hey zusammen!</h3>
          <p>Am <u>31.10.2024 um 20 Uhr</u> steigt die Party im <u>Bevingsweg 20a</u>! Ende offen.</p>
          <p>Ich stelle ein paar Softdrinks, ein bisschen Bier und Snacks.</p>
          <p>Es wäre super, wenn ihr euch beteiligen könntet, entweder mit mitgebrachten Speisen/Getränken oder einem
            kleinen Obulus, natürlich alles auf freiwilliger Basis!</p>

          <h3>Wegbeschreibung:</h3>
          <ul>
            <li>
              CarSharing
              <ul>
                <li>!! SHARENOW FÄHRT NICHT BIS ZU MEINER TÜR !! <br/>
                  (Ihr könnt dann bis zu dem Parkplatz Höhenberg/Frankfurterstraße fahren, dann entweder 15 Minuten über die Heide laufen oder dort die Linie 1 nehmen)</li>
              </ul>
            </li>
            <li>
              Bahn
              <ul>
                <li>Linie 1 bis Merheim, entweder laufen oder Bus 157 Richtung Holweide/Höhenhaus bis Bevingsweg (1 Station)</li>
                <li>Linie 3/13/18 bis Holweide Vischeringstraße und dort den Bus Richtung Ostheim bis Bevingsweg</li>
              </ul>
            </li>
            <li>
              Auto
              <ul>
                <li>Parken könnt ihr entweder vor der Tür oder der Nebenstraße.</li>
              </ul>
            </li>
            <li>Klingeln bei Frank/Schmidt</li>
          </ul>
        </div>
        <br/>
        <br/>
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2113.692138722313!2d7.040251450813694!3d50.94928224439395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bf2636fd752569%3A0x38b282917a5625d2!2sBevingsweg%2020A%2C%2051109%20K%C3%B6ln!5e0!3m2!1sde!2sde!4v1697135879011!5m2!1sde!2sde"
            style={{
              width: '100%',
              height: '500px',
              border: 0
            }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
        {/*<Form code={code}/>*/}
      </div>
    );
  }
  return (
    <div className={styles.home}>
      <h1 style={{fontFamily: 'sans-serif'}}>Du hast keinen Zugriff</h1>
    </div>
  );
};

Home.getLayout = (page) => {
  return(
    page.props?.validCode
      ? <MainLayout>{page}</MainLayout>
      : <PlainLayout>{page}</PlainLayout>
  )
};

export default Home;
