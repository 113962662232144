import {ErrorMessage, Field, Form as FormikForm, Formik} from "formik";
import {redirect} from "next/navigation";

const validate = (value,  type = null, required = false) => {
  if (required) {
    if (!value) {
      return 'Benötigt';
    }
  }

  switch (type) {
    default:
      return null;
  }
};

const validateInputs = (values) => {
  return [
    'name',
  ].reduce((errors, current) => {
    // @ts-ignore
    const message = validate(values[current], current, true);
    if (message) {
      errors[current] = message;
    }
    return errors;
  }, {});
};

const saveDaily = async (values) => {
    return await fetch(
      '/api/saveForm',
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
          },
          body: JSON.stringify({
              data: values
          }),
      }
  )
      .then(res => res.json());
}

const Form = ({code}) => {
  return (
    <Formik
      initialValues={{
        entry_code: code,
        name: '',
        accompaniment: false,
        bring_along: '',
      }}
      validate={validateInputs}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setTimeout(async () => {
          setSubmitting(false);
          const res = await saveDaily(values);
          if(res){
            resetForm();
            alert('Danke für deine Anmeldung')
          }
        });
      }}
    >
      {({ errors, touched }) => (
          <FormikForm className='form'>
            <Field
              name='entry_code'
              type='hidden'
            />
            <div className="form-group">
              <label htmlFor='name'>
                <span className="form-group__label">Name</span>
                <Field
                  name='name'
                  type='text'
                  placeholder="Name"
                  className="form-group__field"
                />
                <ErrorMessage name='name' render={(msg) => <div className="form-group__error">{msg}</div>} />
              </label>
            </div>
            <div className="form-group form-group--checkbox">
              <label htmlFor='accompaniment' className='form-group__sbs'>
                <span className="form-group__label">Mit Begleitung</span>
                <Field
                  name='accompaniment'
                  type='checkbox'
                  placeholder="Begleitung"
                  className="form-group__field"
                />
                <ErrorMessage name='accompaniment' render={(msg) => <div className="form-group__error">{msg}</div>} />
              </label>
            </div>
            <div className="form-group">
              <label htmlFor='bring_along'>
                <span className="form-group__label">Ich bringe mit</span>
                <Field
                  name='bring_along'
                  type='text'
                  placeholder="Salat, Dübel, Schrauben, 400 Liter Bier und ein Feuerzeug"
                  className="form-group__field"
                />
                <ErrorMessage name='bring_along' render={(msg) => <div className="form-group__error">{msg}</div>} />
              </label>
            </div>
            <div className="mt-10">
              <button type="submit" className="form__button">Anmelden</button>
            </div>
          </FormikForm>
      )}
    </Formik>
  );
}

export default Form;
