import React from "react";
import Image from "next/image";

import styles from './MainLayout.module.scss';

import Front from '@assets/img/header/front.png';
import Pump from '@assets/img/header/l2_pump.png';
import House from '@assets/img/header/l3_ground_house.png';
import Bat1 from '@assets/img/header/l4_bat_1.png';
import Bat2 from '@assets/img/header/l4_bat_2.png';
import Bat3 from '@assets/img/header/l4_bat_3.png';
import Bat4 from '@assets/img/header/l4_bat_4.png';
import Bat5 from '@assets/img/header/l4_bat_5.png';
import Eyes from '@assets/img/header/l5_eyes.png';
import Mooon from '@assets/img/header/l6_moon.png';
import TOT from '@assets/img/header/tot.png';
import BGLeft from '@assets/img/header/bg_left.png';
import BGRight from '@assets/img/header/bg_right.png';

const MainLayout = ({ children }) => (
  <div className={styles.mainLayout}>
    <div className={styles.mainLayout__layers}>
      <div className={styles.mainLayout__layers__backLayer}>
        <div className={styles.mainLayout__layers__backLayer__bg_left}>
          <Image alt="hImage" width={864} height={1024} src={BGLeft}/>
        </div>
        <div className={styles.mainLayout__layers__backLayer__bg_right}>
          <Image alt="hImage" width={1110} height={676} src={BGRight}/>
        </div>
      </div>
      <div className={styles.mainLayout__layers__layer6}>
        <div className={styles.mainLayout__layers__layer6__moon}>
          <Image alt="hImage" width={979} height={979} src={Mooon}/>
        </div>
        <div className={styles.mainLayout__layers__layer6__trick}>
          <Image alt="hImage" width={465} height={423} src={TOT}/>
        </div>
      </div>
      <div className={styles.mainLayout__layers__layer5}>
        <div className={styles.mainLayout__layers__layer5__eyes}>
          <Image alt="hImage" width={1198} height={561} src={Eyes}/>
        </div>
      </div>
      <div className={styles.mainLayout__layers__layer4}>
        <div className={styles.mainLayout__layers__layer4__bat1}>
          <Image alt="hImage" width={161} height={58} src={Bat1}/>
        </div>
        <div className={styles.mainLayout__layers__layer4__bat2}>
          <Image alt="hImage" width={149} height={82} src={Bat2}/>
        </div>
        <div className={styles.mainLayout__layers__layer4__bat3}>
          <Image alt="hImage" width={118} height={45} src={Bat3}/>
        </div>
        <div className={styles.mainLayout__layers__layer4__bat4}>
          <Image alt="hImage" width={158} height={87} src={Bat4}/>
        </div>
        <div className={styles.mainLayout__layers__layer4__bat5}>
          <Image alt="hImage" width={128} height={51} src={Bat5}/>
        </div>
      </div>
      <div className={styles.mainLayout__layers__layer3}>
        <div className={styles.mainLayout__layers__layer3__house}>
          <Image alt="hImage" width={1403} height={876} src={House}/>
        </div>
      </div>
      <div className={styles.mainLayout__layers__layer2}>
        <div className={styles.mainLayout__layers__layer2__pump}>
          <Image alt="hImage" width={1054} height={357} src={Pump}/>
        </div>
      </div>
      <div className={styles.mainLayout__layers__front}>
        <div className={styles.mainLayout__layers__front__pump}>
          <Image alt="hImage" width={687} height={276} src={Front}/>
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  </div>
);

export default MainLayout;
